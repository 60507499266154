<template>
  <form
    class="form-input flex justify-between p-0"
    @submit.prevent="search()"
  >
    <div class="relative w-full flex items-baseline">
      <input
        v-model="query"
        type="text"
        name="query"
        class="w-full focus:outline-none flex-1 m-0 p-2"
        :placeholder="placeholder"
        @keyup="showClearSearch = true"
      >
      <span
        v-if="showClearSearch"
        @click="clearSearch"
      >
        <Icon
          view-box="0 0 14 14"
          class="text-gray-500 transform rotate-45 w-3 h-3 mr-3 cursor-pointer"
        >
          <Plus />
        </Icon>
      </span>
    </div>
    <button
      class="focus:outline-none inline-block px-2 py-1 font-medium leading-tight duration-150 hover:text-gray-900 bg-gray-200 text-gray-700 m-0"
      @onClick="search()"
    >
      Search
    </button>
  </form>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Icon,
    Plus
  },

  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      query: '',
      showClearSearch: false
    }
  },

  mounted() {
    this.query = this.searchQuery
  },

  methods: {
    search() {
      this.$emit('search', this.query)
    },

    clearSearch() {
      this.query = ''
      this.showClearSearch = false
      this.search()
    }
  }
}
</script>
